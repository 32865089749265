import React, { Component } from 'react';
import { CenterText } from "../../smart/cells";
import ModificationLabel from "../../bookings/modification_label";

export default class StatusCell extends Component {
  render() {
    return (
      <CenterText className='status-column'>
        <ModificationLabel value={this.props.value}/>
      </CenterText>
    )
  }
};
