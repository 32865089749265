import React, { Component } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import TypeLabel from './type_label';
import BookingOptionItemsBlock from "../booking_option_items/block";

export default class Item extends Component {
  handleEdit = () => {
    const { id, onEdit } = this.props;
    if (onEdit) onEdit(id)
  };

  handleDelete = () => {
    const { id, onDelete } = this.props;
    if (onDelete) onDelete(id)
  };

  handleDisable = () => {
    const { id, onEnable } = this.props;
    if (onEnable) onEnable(id)
  };

  handleEnable = () => {
    const { id, onDisable } = this.props;
    if (onDisable) onDisable(id)
  };

  actionButtons() {
    const {
      can_be_deleted,
      disabled
    } = this.props;

    let buttons = [];

    buttons.push(<Button bsStyle='primary' onClick={this.handleEdit}>Edit</Button>);

    if (can_be_deleted) {
      buttons.push(<Button bsStyle='danger' onClick={this.handleDelete}>Delete</Button>);
    } else {
      if (disabled) {
        buttons.push(<Button className="btn btn-green" onClick={this.handleEnable}>Enable</Button>);
      } else {
        buttons.push(<Button bsStyle='warning' onClick={this.handleDisable}>Disable</Button>);
      }
    }

    return buttons;
  }

  render() {
    const {
      id,
      title,
      price,
      booking_option_items_type
    } = this.props;

    return (
      <tr data-id={id}>
        <td>{title}</td>
        <td className="text-right">{price}</td>
        <td className="text-center">
          <TypeLabel value={booking_option_items_type}/>
        </td>
        <td>
          <BookingOptionItemsBlock bookingOption={this.props}/>
        </td>
        <td>
          <ButtonGroup bsSize='xs'>
            {this.actionButtons()}
          </ButtonGroup>
        </td>
      </tr>
    );
  }
}