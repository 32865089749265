import React, { Component } from 'react';
import Tabs from '../areas/tabs';
import Info from './info';
import Images from '../images/block';
import Vats from '../vats/block';
import BookingOptions from '../booking_options/block';
import Extras from '../extras/block';
import MultipleAttendeeDiscounts from '../multiple_attendees_discounts/block';
import Documents from '../documents/block';
import Questions from '../questions/block';

export default class Show extends Component {
  constructor(props) {
    super(props);
    this.state = { data: { internal_reference: 'Activity' } };
  }

  updateData = (data) => { console.log(data); this.setState({ data })};

  render() {
    let { internal_reference } = this.state.data;

    return (
      <div>
        <h2>{internal_reference}</h2>
        <Tabs
          list={
            [
              {
                name: 'info',
                title: "Information",
                component: <Info name="Activity" updatePageData={this.updateData} path={window.location.pathname}/>
              },
              {
                name: 'images',
                title: "Images",
                component: (
                  <div>
                    <Images
                      path={window.location.pathname + '/images'}
                      title='Image'
                      name="Image"
                    />
                  </div>
                )
              },
              {
                name: 'pricing',
                title: "Pricing",
                component: (
                <div>
                  <Vats path={window.location.pathname + '/vat'}/>
                  <MultipleAttendeeDiscounts
                    title='Multiple Attendee Discount'
                    name="MultipleAttendeeDiscount"
                    path={window.location.pathname + '/multiple_attendees_discounts'}
                  />
                  <BookingOptions
                    title='Booking Option'
                    name="BookingOption"
                    path={window.location.pathname + '/booking_options'}
                  />
                  <Extras
                    title='Extra'
                    name="Extra"
                    path={window.location.pathname + '/extras'}
                  />
                </div>
                ),
              },
              {
                name: 'documents',
                title: "Documents",
                component: (
                  <Documents
                    title='Document'
                    name='Document'
                    path={window.location.pathname + '/documents'}
                  />
                )
              },
              {
                name: 'questions',
                title: "Questions",
                component: (
                  <Questions
                    title='Question'
                    name='Question'
                    path={window.location.pathname + '/questions'}
                  />
                )
              }
            ]
          }
        />
      </div>
    );
  }
}
