import React, { Component } from 'react';
import BookingItemsTable from "../booking_items/table";
import SplitPaymentsTable from "../split_payments/table";
import Edit from '../bills/edit';
import axios from 'axios';
import PubSub from 'pubsub-js';
import ApproveErrorModal from './approve_error_modal';

export default class Bill extends Component {
  constructor(props) {
    super(props);

    this.path = window.location.pathname;
  }

  componentDidMount() {
    PubSub.subscribe('BookingItem.Updated', this.props.onUpdate)
  }

  handleApprove = (e) => {
    e.preventDefault();

    const { onUpdate, data } = this.props;

    axios
      .post(`${this.path}/bills/${data.id}/approval.json`)
      .then(() => { onUpdate() })
      .catch((error) => {
        PubSub.publish('Bills.ApprovalError', { errors: error.response.data.errors.base })
      });
  };

  handleCancel = (e) => {
    e.preventDefault();

    const { onUpdate, data } = this.props;

    axios
      .delete(`${this.path}/bills/${data.id}.json`)
      .then(() => { onUpdate() })
      .catch((error) => console.log(error));
  };

  handleRemoveBookingItem = (bookingItemId) => {
    const { data, onUpdate } = this.props;
    const { id } = data;

    axios
      .delete(`${window.location.pathname}/bills/${id}/booking_items/${bookingItemId}.json`)
      .then(() => { onUpdate() })
      .catch((error) => console.log(error));
  };

  handleEditBookingItem = (bookingItemId) => {
    let { data }  =  this.props;

    let booking = data;
    let bookingItems = data.booking_items.data;
    let bookingItem = bookingItems.find(item => item.id === bookingItemId);

    let params = {
      id: bookingItemId,
      venueId: data.venue.data.id,
      bookingId: booking.id,
      bookingItemId: bookingItem.id,
      activityId: bookingItem.activity.id,
      activityCategoryId: data.activity_category_id,
      bookingOptionId: bookingItem.booking_option_id,
      bookingOptionItemId: bookingItem.booking_option_item_id,
      eventDates: bookingItem.schedule_items.map(item => item.event_date)
    };

    PubSub.publish('BookingItem.Edit', params)
  };

  render() {
    const { data, onUpdate } = this.props;

    if (!data) return null;

    const { status } = data;

    let fundEditItem = undefined;
    let fundRemoveItem = undefined;

    if (status === 'pending') {
      fundEditItem = this.handleEditBookingItem;
      fundRemoveItem = this.handleRemoveBookingItem;
    }

    return (
      <div style={{ outline: '2px solid #1ea800', margin: '0 -10px 0 -10px', padding: '10px 10px 10px 10px' }}>
        <div className="section">
          <div className="section-header">
            <h3>List of sessions</h3>
          </div>

          <BookingItemsTable
            items={data.booking_items.data}
            billData={data}
            onRemoveItem={fundRemoveItem}
            onEditItem={fundEditItem}
          />
        </div>

        {data.split_payments.data && data.split_payments.data.length > 1 &&
          <div className="section">
            <div className="section-header">
              <h3>Payment Schedule</h3>
            </div>

            <SplitPaymentsTable
              items={data.split_payments.data}
              included={data.split_payments.included}
            />
          </div>
        }

        {data.edit_price_adjustment &&
          <div>
            <Edit path={this.path} {...data} onUpdate={onUpdate}/>

            <div className="btn-toolbar pull-right">
              <a className="btn btn-danger" href="#" onClick={this.handleCancel}>Cancel</a>
              <a className="btn btn-primary" href="#" onClick={this.handleApprove}>Confirm</a>
            </div>

            <ApproveErrorModal title={'Title'}/>
          </div>
        }

        <div className="clearfix"/>
      </div>
    );
  }
}