import React, { Component, Fragment } from 'react';
import BillTotals from './bill_totals';
import BookingItem from './booking_item';
import PaymentOptions from './payment_options';
import PromoCode from './promo_code';
import Modal from './modal';
import { Button } from 'react-bootstrap';

export default class Table extends Component {
  render() {
    const {
      items,
      billData,
      onRemoveItem,
      onEditItem,
      extendedList,
      internalNotes,
      selectedScheduleItemIds
    } = this.props;

    if (items === null) return null;

    const { status } = billData;

    return (
      <div>
        {status === 'pending' &&
          <Modal
            path={'admin/booking_items'}
            title="Booking Item"
            name="BookingItem"
          />
        }

        <div className="table-block">
          <table className="table table-bordered-2">
            <thead>
            <tr>
              <th scope="col">Attendee</th>
              <th scope="col">DoB</th>
              <th scope="col">Activity</th>
              <th scope="col">Booking option</th>
              <th scope="col">Start date</th>
              <th scope="col">Price</th>
              <th scope="col"/>
              <th scope="col"/>
            </tr>
            </thead>
            {items.map((item) =>
              <BookingItem
                key={item.id}
                onRemoveItem={onRemoveItem}
                onEditItem={onEditItem}
                extendedList={extendedList}
                internalNotes={internalNotes}
                selectedScheduleItemIds={selectedScheduleItemIds}
                {...item}
              />
            )}
          </table>
        </div>
        <div className="row vert-offset-bottom-10">
          <div className="col-md-6">
          </div>
          <div className="col-md-6">
            {!internalNotes &&
            <BillTotals bill={billData}/>
            }
          </div>
        </div>

        {!internalNotes &&
        <Fragment>
          <div className="row">
            <div className="col-lg-12">
              <PaymentOptions bill={billData} onUpdate={this.props.onUpdate}/>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <PromoCode bill={billData} onUpdate={this.props.onUpdate}/>
            </div>
          </div>
        </Fragment>
        }
      </div>
    );
  }
}
