import React from 'react';
import SmartModal from '../../smart/modal';
import FieldGroup from '../../smart/field_group';

export default class Modal extends SmartModal {
  renderForm() {
    return (
      <div>
        <FieldGroup
          type="text"
          label="Title"
          name="extra[title]"
          defaultValue={this.formValue("title")}
          error={this.formError("title")}
        />
        <FieldGroup
          type="select"
          options={this.includedData('price_calculation_types')}
          label="Pricing calculation type"
          name="extra[pricing_calculation_type]"
          defaultValue={this.formValue("pricing_calculation_type")}
          error={this.formError("pricing_calculation_type")}
        />
        <FieldGroup
          type="text"
          label="Price"
          name="extra[price]"
          defaultValue={this.formValue("price")}
          error={this.formError("price")}
        />
        <FieldGroup
          type="checkbox"
          placeholder={<strong>Apply discounts</strong>}
          name="extra[apply_discounts]"
          defaultChecked={this.formValue("apply_discounts")}
          error={this.formError("apply_discounts")}
        />
      </div>
    );
  }
}
