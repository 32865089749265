import React, { Component} from 'react';
import BookingOption from './booking_option';

export default class BookingOptions extends Component {
  render() {
    const { bookingOptions, onChange, selectedBookingOptionId } = this.props;

    if (!(Array.isArray(bookingOptions) && bookingOptions.length > 0)) return null;

    let filteredBookingItems = bookingOptions.filter(bookingOption =>
      bookingOption.booking_option_items.length > 0
    );

    return (
      <div className="section" id='booking-options-section'>
        <h4>Choose booking option</h4>
        <div>
          {filteredBookingItems.map(bookingOption => {
            let checked = (selectedBookingOptionId === bookingOption.id);

            return (
              <BookingOption
                key={bookingOption.id}
                {...bookingOption}
                onChange={onChange}
                checked={checked}
                bookingOption={bookingOption}
              />
            )
          })}
        </div>
      </div>
    );
  }
}
