import React  from 'react';

const Copyright = props => {
  return (
    <div>
      <a href="/">
        <img alt="SchoolHire" src={require('images/logo_w.png')}/>
      </a>
      <p className="copy">
        © 2015-2018 Copyright School Lettings Ltd.<br/>
        All rights reserved.
        <a href="/terms_and_conditions">T&amp;C and Policies</a>
      </p>
    </div>
  )
};

export default Copyright;