import React, { Component } from 'react'
import PlacesAutocomplete from 'react-places-autocomplete'

export default class PlaceInput extends Component {
  constructor(props) {
    super(props);
    this.state = { address: props.defaultValue || '' }
  }

  handleChange = (address) => {
    this.setState({ address }, () => { console.log(this.state) })
  };

  handleSelect = (address) => {
    this.setState({ address: address });
  };

  render() {
    const { name } = this.props;

    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Location',
                className: 'location-search-input form-control',
                name: name
              })}
            />
            <div className="autocomplete-dropdown-container">
              {suggestions.map(suggestion => {
                const className = suggestion.active ? 'suggestion-item active' : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div {...getSuggestionItemProps(suggestion, { className, style })}>
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}