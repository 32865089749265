import React, { Component } from 'react';
import { AbilityContext } from '../../ability_context';
import { ActionsCell as ParentActionsCell } from '../../smart/cells';
import PubSub from 'pubsub-js';

export const ActiveCell = props => {
  let activeLabel = <span className="label label-primary">Active</span>;
  let notActiveLabel = <span className="label label-danger">Not active</span>;

  return (
    <div className="text-center">
      { props.active ? activeLabel : notActiveLabel }
    </div>
  );
};

export const PublishedCell = props => {
  let publishedLabel = <span className="label label-primary">Published</span>;
  let notPublishedLabel = <span className="label label-danger">Not published</span>;

  return (
    <div className="text-center">
      { props.published && !props.archived ? publishedLabel : notPublishedLabel }
    </div>
  );
};

export const ArchivedCell = props => {
  let archivedLabel = <span className="label label-danger">Archived</span>;
  let notArchivedLabel = <span className="label label-primary">Not archived</span>;

  return (
    <div className="text-center">
      { props.archived ? archivedLabel : notArchivedLabel }
    </div>
  );
};

export class ActionsCell extends Component {
  handlePublish(row) {
    const { published } = row;
    PubSub.publish('Activity.Publish', { id: row.id, modalAction: (published ? 'Unpublish' : 'Publish') });
  }

  handleArchive(row) {
    const { archived } = row;
    PubSub.publish('Activity.Archive', { id: row.id, modalAction: (archived ? 'Unarchive' : 'Archive') });
  }

  handleEdit(row) {
    window.location.href = `/admin/activities/${row.id}`;
  }

  handlePreview(row) {
    window.open(row.seo_link, '_blank');
  }

  handleDelete(row) {
    PubSub.publish('Activity.Delete', { id: row.id });
  }

  handleMessageToAll(row) {
    PubSub.publish(
      'GroupMessage.New',
      {
        inbox: { type: 'Venue', id: row.venue_id },
        interlocutors: row.users
      },
    )
  }

  render() {
    const { row } = this.props;
    const { published, archived } = row;

    return (
      <AbilityContext.Consumer>
        {(ability) =>
          <ParentActionsCell
            row={row}
            bsSize='xs'
            items={[
              {
                title: (published ? 'Unpublish' : 'Publish'),
                action: this.handlePublish,
                hidden: archived
              },
              {
                title: (archived ? 'Unarchive' : 'Archive'),
                action: this.handleArchive
              },
              { title: 'Edit', action: this.handleEdit },
              { title: 'Message to all', action: this.handleMessageToAll },
              { title: 'Preview', action: this.handlePreview },
              {
                title: 'Delete',
                action: this.handleDelete,
                hidden: !ability.can('delete', 'AdminActivities')
              }
            ]}
          />
        }
      </AbilityContext.Consumer>
    );
  }
}
