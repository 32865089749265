import React, { Component } from 'react';
import ReactSummernote from 'react-summernote';
import 'react-summernote/dist/react-summernote.css';

export default class ControlledEditor extends Component {
  constructor(props) {
    super(props);

    this.state = { content: props.defaultValue };
  }

  onInit = (content) => {
    console.log('init');
    if (content) {
      this.setState({content})
    }
  };

  onChange = (content) => {
    console.log('change');
    if (content) {
      this.setState({content})
    }
  };

  render() {
    const { name, defaultValue } = this.props;
    const { content } = this.state;

    return (
      <div>
        <ReactSummernote
          value={defaultValue}
          options={{
            height: 350,
            dialogsInBody: true,
            toolbar: [
              ['style', ['style']],
              ['font', ['bold', 'underline', 'clear']],
              ['fontname', ['fontname']],
              ['para', ['ul', 'ol', 'paragraph']],
              ['table', ['table']],
              ['insert', ['link']],
              ['view', ['fullscreen']]
            ]
          }}
          onInit={() => this.onChange(content)}
          onChange={this.onChange}
        />
        <input name={name} value={content} onChange={() => {}} className="hidden"/>
      </div>
    )
  }
}
