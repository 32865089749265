import React, { Component } from 'react';
import classnames from 'classnames';
import Modal from './modal';
import ErrorBoundary from '../../error_boundary';
import ActivityPeriod from './activity_period';
import InvolvedScheduleItem from './involved_schedule_item';
import Formatted from './../../formatted';

export default class WeekCalendar extends Component {
  constructor(props) {
    super(props)
  }

  componentDidUpdate = () => {
    $('.week-view-calendar').scroll(() => {
      let a = $('.calendar-grid').offset();
      let b = $('.calendar-grid').position();
      console.log(a, b)

      $('.calendar-cell-activity').css({ left : -b.left });
    });


    window.onscroll = function() {
      $(document).find('.calendar-grid').each(function(index, el) {
        let top = el.getBoundingClientRect().top;

        let a = $('.calendar-grid').offset();

        if (top < 0) {
          let w = $(el).find('.calendar-header-space').width();
          $(el).find('.calendar-header').addClass('absolute').data({ act: true }).css({ width: w, top: -top });
          $(el).find('.calendar-header-space').addClass('calendar-header-space-visible');
        } else {
          $(el).find('.calendar-header').removeClass('absolute').data({ act: false });
          $(el).find('.calendar-header-space').removeClass('calendar-header-space-visible');
        }
      });
    };
  };

  renderActivityEventDate(activityId, eventDate) {
    let list = this.getList(activityId, eventDate);
    if (!list) return null;
    return list.map((item, index) => <InvolvedScheduleItem key={index} {...item}/>)
  }

  isOpenedDay(activityId, eventDate) {
    return this.getList(activityId, eventDate);
  }

  getList(activityId, eventDate) {
    const { data } = this.props;
    const scheduleItems = data.schedule_items.data;

    if (!scheduleItems) return null;

    let activity = scheduleItems[activityId];
    if (!activity) return null;

    let list = activity[eventDate];
    if (!list) return null;

    return list;
  }

  handlePrev = (e) => {
    e.preventDefault();

    const { onPrev } = this.props;
    if (onPrev) onPrev();
  };

  handleNext = (e) => {
    e.preventDefault();

    const { onNext } = this.props;
    if (onNext) onNext();
  };

  render() {
    const { data } = this.props;

    if (data === null || data === undefined) return null;

    const activities = data.activities.data;
    const dates = data.dates.data;

    if (!(Array.isArray(activities) && activities.length > 0)) return null;

    return (
      <div className="week-view-calendar" style={{ width: '100%', overflowY: 'scroll' }}>
        <Modal path={window.location.pathname} title="" name="Calendar"/>

        <div className='calendar-grid'>
          <div className='calendar-header-space'/>

          <div className="calendar-row calendar-header">
            <div className="calendar-cell calendar-header-cell"/>
            {dates.map((date, index) =>
              <div key={index} className="calendar-cell calendar-header-cell">
                <Formatted.Date value={date}/>
              </div>
            )}
          </div>

          {activities.map((activity, index) =>
            <div key={index} className="calendar-row">
              <div className="calendar-cell calendar-body-cell">
                <ErrorBoundary>
                  <div>
                    <a target='_blank' href={`/admin/activities/${activity.id}`}>
                      {activity.internal_reference}
                    </a>
                  </div>
                  <ActivityPeriod eventsPeriod={activity.events_period}/>
                </ErrorBoundary>
              </div>

              <div className="calendar-cell calendar-body-cell calendar-cell-activity">
                <ErrorBoundary>
                  <div>
                    <a target='_blank' href={`/admin/activities/${activity.id}`}>
                      {activity.internal_reference}
                    </a>
                  </div>
                  <ActivityPeriod eventsPeriod={activity.events_period}/>
                </ErrorBoundary>
              </div>

              {dates.map((date, index2) => {
                let openedDay = this.isOpenedDay(activity.id, date);
                let trClasses = classnames([
                  { 'calendar-cell': true },
                  { 'calendar-body-cell': true },
                  { 'day': true },
                  { 'open-day': openedDay },
                  { 'close-day': !openedDay }
                ]);

                return (
                  <div key={index2} className={trClasses}>
                    <ErrorBoundary>
                      {this.renderActivityEventDate(activity.id, date)}
                    </ErrorBoundary>
                  </div>
                )
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
