import FieldGroup from "../../../smart/field_group";
import React from "react";

export default ({ onChange }) => (
  <FieldGroup
    label="Statuses:"
    type="checkbox-group"
    name="statuses"
    options={[
      { value: "accepted", title: "Accepted", defaultChecked: true },
      { value: "cancelled", title: "Cancelled", defaultChecked: true },
      { value: "completed", title: "Completed", defaultChecked: true }
    ]}
    onChange={onChange}
  />
)
