import React, { Component } from 'react';
import SocialBlock from "../shared/social_block";
import MessageButton from "./message_button";
import Gallery from "../areal/gallery";
import ActivityCategories from "./activity_categories";
import Breadcrumbs from "../shared/breadcrumbs";
import Description from "./description";
import Location from "./location";
import axios from 'axios';

export default class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { venue_id } = window.PageData;

    axios
      .get(`/venues/${venue_id}.json`)
      .then((response) => this.setState({ data: response.data.data }))
      .catch((error) => console.log(error));
  };

  render() {
    const { data } = this.state;
    if (data === undefined) return null;

    const {
      id,
      breadcrumbs,
      activity_categories,
      title,
      address,
      share,
      images,
      description,
      latitude,
      longitude
    } = data;

    return (
      <div className="wrapper venue-wrapper">
        <div className="breadcrumb-menu">
          <Breadcrumbs data={breadcrumbs}/>
        </div>

        <div className="top-name">
          <div className="row">
            <div className="col-xs-12 venue-main">

              <div className="row" style={{ marginBottom: 15 }}>
                <div className="col-md-9">
                  <h1>
                    {title}
                  </h1>
                  <h2>
                    Address: {address}
                  </h2>
                </div>
                <div className="col-md-3 col-sm-2 venue-share-block">
                  <SocialBlock links={share}/>
                  <MessageButton/>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12">
                  <Gallery images={images}/>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="content-main">
          <div className="row">
            <div className="col-lg-12 content-main">
              <ActivityCategories venueId={id} list={activity_categories}/>
              <Description text={description}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
