import React, { Component} from 'react';
import Formatted from '../formatted';

export default class BookingOption extends Component {
  handleChange = () => {
    // We use booking option only as parameter for onChange
    const { onChange, bookingOption } = this.props;
    onChange(bookingOption);
  };

  render() {
    const {
      id, title, price, price_with_vat,
      start_datetime, end_datetime,
      pay_per_day, checked
    } = this.props;

    return (
      <div style={{ position: 'relative' }}>
        <input
          defaultValue={id}
          name="booking_option_id"
          className='booking-option-radio'
          type="radio"
          checked={checked}
          onChange={this.handleChange}
        />

        <div className="panel panel-default booking-option-panel">
          <div className="panel-body">
            {price &&
            <div className="price-box pull-right">
              <Formatted.Price price={price_with_vat}/>
            </div>
            }

            {pay_per_day &&
            <div className="pay-per-day-box pull-right">
              Pay as you go
            </div>
            }

            <div className="title">
              {title}
              &nbsp;-&nbsp;
              <span className="start-date">
                {pay_per_day &&
                  <>
                    <Formatted.Time showSeconds={false} value={start_datetime}/>
                    &nbsp;-&nbsp;
                    <Formatted.Time showSeconds={false} value={end_datetime}/>
                  </>
                }

                {!pay_per_day &&
                <>
                  From <Formatted.Date date={start_datetime}/>:&nbsp;
                  <Formatted.Time showSeconds={false} value={start_datetime}/>
                  &nbsp;-&nbsp;
                  <Formatted.Time showSeconds={false} value={end_datetime}/>
                </>
                }
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
