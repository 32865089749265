import React from 'react';
import SmartBlock from '../../smart/block';
import Modal from './modal';
import Formatted from '../../formatted';

export default class Block extends SmartBlock {
  renderTable() {
    const { data } = this.state;

    if (!(Array.isArray(data) && data.length > 0)) return null;
    return (
      <table className="table table-striped table-bordered table-xs">
        <thead>
        <tr>
          <th>Title</th>
          <th>Type</th>
          <th>Amount</th>
          <th>Apply discounts</th>
          <th style={{width: 100}}></th>
        </tr>
        </thead>
        <tbody>

        {data.map((item) => {
          let priceBoxValue = null;

          if (item.pricing_calculation_type === 'percent') {
            priceBoxValue = <Formatted.Percent percent={item.price}/>
          } else {
            priceBoxValue = <Formatted.Price price={item.price}/>
          }

          return (
            <tr key={item.id}>
              <td>{item.title}</td>
              <td className="text-center">{item.pricing_calculation_type}</td>
              <td className="text-right">{priceBoxValue}</td>
              <td className="text-center">{item.apply_discounts ? 'Yes' : 'No'}</td>
              <td>
                <div className="btn-group">
                  <a className="btn btn-xs btn-primary" onClick={(e) => this.handleEdit(item.id)}>Edit</a>
                  <a className="btn btn-xs btn-danger" onClick={(e) => this.handleDelete(item.id)}>Delete</a>
                </div>
              </td>
            </tr>
          )
        })}
        </tbody>
      </table>
    );

  }

  render() {
    return (
      <div>
        <Modal {...this.props}/>

        <div className="panel panel-default panel-block">
          <div className="panel-heading">
            <h3 className="pull-left">Extras</h3>
            <div className="btn-group pull-right">
              <a className="btn btn-primary" onClick={this.handleNew}>Add extra</a>
            </div>
            <div className="clearfix"/>
          </div>
          <div className="panel-body panel-body-with-table">
            <div className="table-box">
              {this.renderTable()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

