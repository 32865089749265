import React, { Component } from 'react';
import moment from 'moment';
import MomentLocaleUtils, { formatDate } from 'react-day-picker/moment';
import Select from 'react-select';
import ErrorBoundary from "../error_boundary";

const customMonthStyles = {
  container: (base, state) => ({
    ...base,
    borderBottom: '1px solid #337ab7',
    cursor: 'pointer',
    color: '#337ab7'
  }),
  menu: (base, state) => ({
    ...base,
    width: '255px',
    paddingLeft: '5px'
  }),
  option: (base, state) => ({
    ...base,
    width: '80px',
    float: 'left',
    textAlign: 'center',
    padding: 5,
    border: '1px solid #eee',
    color: '#000',
    fontWeight: 'normal',
    margin: 1,
    backgroundColor: '#eee',
    borderRadius: '3px',
    cursor: 'pointer',
    textTransform: 'capitalize'
  }),
};

const customYearStyles = {
  container: (base, state) => ({
    ...base,
    borderBottom: '1px solid #337ab7',
    cursor: 'pointer',
    color: '#337ab7',
  }),
  menu: (base, state) => ({
    ...base,
    width: '217px',
    paddingLeft: '5px'
  }),
  option: (base, state) => ({
    ...base,
    width: '50px',
    float: 'left',
    textAlign: 'center',
    padding: 5,
    border: '1px solid #eee',
    color: '#000',
    fontWeight: 'normal',
    backgroundColor: '#eee',
    borderRadius: '3px',
    margin: 1,
    cursor: 'pointer'
  }),
};

export default class DatePickerInput extends Component {
  constructor(props) {
    super(props);

    let { fromMonth, toMonth, month } = props;

    let years = [];
    for (let year = fromMonth.getFullYear(); year <= toMonth.getFullYear(); year += 1) {
      years.push({ value: year, label: year });
    }

    console.log(fromMonth, toMonth)

    this.years = years;
    this.months = MomentLocaleUtils.getMonths().map((month, index) => ({ value: index, label: month }));

    let currentMonth = moment(month).toDate().getMonth();
    let currentYear = moment(month).toDate().getFullYear();

    console.log('MONTH->', this.getMonth(currentMonth));
    console.log('YEAR->', this.getYear(currentYear));

    this.state = {
      yearOpen: false,
      monthOpen: false,
      selectedMonth: this.getMonth(currentMonth),
      selectedYear: this.getYear(currentYear)
    };
  }

  getMonth(month) {
    return this.months.find((x) => x.value === month);
  }

  getYear(year) {
    return this.years.find((x) => x.value === year);
  }

  componentDidUpdate(prevProps) {
    const { month } = this.props;

    if (prevProps.month !== month) {
      const selectedMonth = this.getMonth(month.getMonth());
      const selectedYear = this.getYear(month.getFullYear());

      this.setState({ selectedYear, selectedMonth });
    }
  }

  handleChangeYear = (selectedYear) => {
    const { onChangeMonth, month } = this.props;
    const monthDate = moment(month).year(selectedYear.value).toDate();
    this.setState({ selectedYear, yearOpen: false }, () => { onChangeMonth(monthDate) });
  };

  handleChangeMonth = (selectedMonth) => {
    const { onChangeMonth, month } = this.props;
    const monthDate = moment(month).month(selectedMonth.value).toDate();
    this.setState({ selectedMonth, monthOpen: false }, () => { onChangeMonth(monthDate) });
  };

  handleShowMonth = () => {
    this.setState({ monthOpen: true, yearOpen: false });
  };

  handleShowYear = () =>  {
    this.setState({ monthOpen: false, yearOpen: true });
  };

  render() {
    const { selectedYear, selectedMonth, yearOpen, monthOpen } = this.state;

    console.log(selectedYear);
    console.log(selectedMonth);


    const EmptyComponent = () => <span/>;

    const MonthControl = (props) => (
      <span onClick={this.handleShowMonth}>
        <ErrorBoundary>
          {selectedMonth.label}
        </ErrorBoundary>
       </span>
    );

    const YearControl = (props) => (
      <span onClick={this.handleShowYear}>
        <ErrorBoundary>
          {selectedYear.label}
        </ErrorBoundary>
      </span>
    );

    return (
      <div className="DayPicker-Caption" role="heading">
        <div className="DayPicker-Caption2">
          <Select
            value={selectedMonth}
            onChange={this.handleChangeMonth}
            options={this.months}
            clearable={false}
            menuIsOpen={monthOpen}
            onBlur={() => this.setState({ monthOpen: false })}
            styles={customMonthStyles}
            components={{
              DropdownIndicator: EmptyComponent,
              IndicatorSeparator: EmptyComponent,
              ValueContainer: EmptyComponent,
              Control: MonthControl
            }}
          />,&nbsp;
          <Select
            value={selectedYear}
            onChange={this.handleChangeYear}
            options={this.years}
            clearable={false}
            menuIsOpen={yearOpen}
            onBlur={() => this.setState({ monthOpen: false })}
            styles={customYearStyles}
            components={{
              DropdownIndicator: EmptyComponent,
              IndicatorSeparator: EmptyComponent,
              ValueContainer: EmptyComponent,
              Control: YearControl
            }}
          />
        </div>
      </div>
    );
  };
}
