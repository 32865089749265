import React, { Component, Fragment } from 'react';
import Formatted from '../../../formatted';

export default class BookingItemRow extends Component {
  render() {
    const {
      date_of_booking,
      invoice_number,
      invoice_date,
      customer_name,
      activity_internal_reference,
      start_datetime,
      end_datetime,
      booking_option,
      net_price,
      vat,
      gross_price,
      date_of_payment,
      bookings_id,
      offline,
      booking_status
    } = this.props;

    let dateOfPaymentValue = offline ? 'Offline Payment' : <Formatted.Date value={date_of_payment}/>;

    return (
      <tr>
        <td className='text-center'>
          <Formatted.Date value={date_of_booking}/>
        </td>
        <td className='text-center'>
          <a target='_blank' href={`/admin/bookings/${bookings_id}`}>
            {invoice_number}
          </a>
        </td>
        <td className='text-center'>
          <Formatted.Date value={invoice_date}/></td>
        <td className='text-center'>
          {customer_name}</td>
        <td className='text-center'>
          {activity_internal_reference}
        </td>
        <td className='text-center'>
          {booking_option}
        </td>
        <td className='text-right'>
          <Formatted.Price price={net_price}/>
        </td>
        <td className='text-right'>
          <Formatted.Price price={vat}/>
        </td>
        <td className='text-right'>
          <Formatted.Price price={gross_price}/>
        </td>
        <td className='text-center'>
          {dateOfPaymentValue}
        </td>
      </tr>
    );
  }
}
