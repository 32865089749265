import React, { Component, Fragment } from 'react';
import SmartTable from '../../smart/table';
import { DateCell, WithTooltip } from '../../smart/cells';
import Modal from './modal';
import UserRoleLabel from '../../users/role_label';
import { ActionsCell } from './cells';

export default class Table extends Component {
  render() {
    return (
      <Fragment>
        <Modal {...this.props}/>

        <SmartTable
          name={this.props.name}
          dataPath='/admin/venues.json'
          columns={[
            {
              Header: "ID",
              accessor: "id",
              width: 70
            },
            {
              Header: "Title",
              accessor: "title",
              Cell: props => (
                <WithTooltip>
                  <a href={props.original.link}>{props.original.title}</a>
                </WithTooltip>
              )
            },
            {
              Header: "Created by",
              accessor: "creator_name",
              width: 300,
              Cell: props => (
                <WithTooltip>
                  <div>
                    <span>{props.original.owner_name}</span>
                    <span style={{ display: 'inline-block', width: 15 }}/>
                    <UserRoleLabel role={props.original.owner_role}/>
                  </div>
                </WithTooltip>
              )
            },
            {
              Header: "Date created",
              accessor: "created_at",
              width: 100,
              Cell: props => <DateCell date={props.value} />
            },
            {
              Header: "Actions",
              sortable: false,
              width: 70,
              style: { overflow: 'inherit' },
              Cell: props => <ActionsCell row={props.original}/>
            }
          ]}
        />
      </Fragment>
    );
  }
}