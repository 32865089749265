import React, { Component } from 'react';
import Map from "../searches/map";

export default class Location extends Component {
  render() {
    const { latitude, longitude } = this.props;

    const center = {lat: latitude, lng: longitude};
    const markers = [{lat: latitude, lng: longitude}];

    return (
      <div className="facilities-maps">
        <h3>
          <img src={require("images/loc-icon.png")} />
          Location
        </h3>
        <Map center={center} markers={markers} />
      </div>
    )
  }
}