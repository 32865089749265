import React, { Component } from 'react';
import { Checkbox } from 'react-bootstrap';

export default class CheckboxGroup extends Component {
  getName(index, extendedName) {
    const { name } = this.props;

    let additionalPart = '';
    let additionalIndex = '';

    if (extendedName) {
      additionalIndex = `${index}`;
      additionalPart = `[${extendedName}]`;
    }

    return `${name}[${additionalIndex}]${additionalPart}`;
  }

  render() {
    const { options, extendedName, onChange } = this.props;

    if (typeof options !== 'object') return null;

    console.log(options);

    return (
      options.map((option, index) =>
        <span key={index} className='checkbox'>
          <input type="hidden" name={this.getName(index, extendedName)} value="0"/>
          <Checkbox {...option} name={this.getName(index, extendedName)} onChange={onChange}>
            {option.title}
            {extendedName &&
              <input type="hidden" name={this.getName(index, 'id')} defaultValue={option.id}/>
            }
          </Checkbox>
        </span>
      )
    );
  }
}
