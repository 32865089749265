import React, { Component, Fragment } from 'react';
import { FormGroup, ControlLabel, FormControl, HelpBlock, Col, InputGroup } from 'react-bootstrap';
import DatePickerInput from './date_picker_input';
import DatePickerInput2 from "./date_picker_input_2";
import TimePickerInput from './time_picker_input';
import TimeSlotInput from './time_slot_input';
import WeekDaysInput from './week_days_input';
import Select from './select';
import Select2 from './select_2';
import SelectBoolean from './select_boolean';
import classnames from 'classnames';
import RadioGroup from "./radio_group";
import NextRadioGroup from "./fields/next_radio_group";
import PlaceInput from "./place_input";
import SliderInput from "./slider_input";
import RangeInput from "./range_input";
import CheckboxGroup from "./checkbox_group";
import DebounceInput from './fields/debounce_input';
import RichText from "./rich_text";
import BirthInput from "./fields/birth_input";
import ErrorBoundary from "../error_boundary";

const Required = () => (
  <abbr title="required">*</abbr>
);

export default class FieldGroup extends Component {
  renderInput(props) {
    switch(props.type) {
      case 'select':         return <Select {...props}/>;
      case 'select2':        return <Select2 {...props}/>;
      case 'checkbox':       return <SelectBoolean {...props} onlyAdmin={this.props.onlyAdmin} type='select'/>;
      case 'date':           return <DatePickerInput {...props}/>;
      case 'date2':          return <DatePickerInput2 {...props}/>;
      case 'time':           return <TimePickerInput {...props}/>;
      case 'time-slot':      return <TimeSlotInput {...props}/>;
      case 'week-days':      return <WeekDaysInput {...props}/>;
      case 'input-group':    return <FormControl {...props}/>;
      case 'textarea':       return <FormControl componentClass="textarea" {...props}/>;
      case 'radio-group':    return <RadioGroup {...props}/>;
      case 'next-radio-group':    return <NextRadioGroup {...props}/>;
      case 'checkbox-group': return <CheckboxGroup {...props}/>;
      case 'location':       return <PlaceInput {...props}/>;
      case 'slider':         return <SliderInput {...props}/>;
      case 'range':          return <RangeInput {...props}/>;
      case 'text-debounce':  return <DebounceInput {...props}/>;
      case 'rich-text':      return <RichText {...props}/>;
      case 'birth':          return <BirthInput {...props}/>;
      default:               return <FormControl {...props} />;
    }
  }

  render() {
    let {
      id,
      label,
      error,
      formType,
      showLabel,
      required,
      className,
      custom,
      onlyAdmin,
      hidden,
      replaced,
      replacedValue,
      replacedDefaultValue,
      ...props
    } = this.props;


    if (showLabel === undefined) { showLabel = true }
    if (label === false) { showLabel = false }
    if (label === undefined) { showLabel = false }
    if (onlyAdmin) { label = <span className="sha-admin-highlight">{label}</span> }

    let formGroupClassName = classnames([className, { 'has-error': error, 'hidden': hidden }]);

    let input = <Fragment/>;

    if (replaced) {
      let replacedProps = {...props};
      let replacedProps2 = {...props};

      replacedProps['disabled'] = true;
      replacedProps2['disabled'] = true;
      replacedProps2['value'] = replacedValue;

      input = (
        <Fragment>
          <div className='hidden'>
            {this.renderInput(replacedProps)}
          </div>
          {this.renderInput(replacedProps2)}
        </Fragment>
      );
    } else {
      input = this.renderInput(props);
    }

    const {
      prependLabel,
      appendLabel,
      prependInputGroup,
      appendInputGroup
    } = (custom || {});

    if (prependInputGroup || appendInputGroup) {
      input = (
        <InputGroup>
          {prependInputGroup && <InputGroup.Addon>{prependInputGroup}</InputGroup.Addon>}
          {input}
          {appendInputGroup && <InputGroup.Addon>{appendInputGroup}</InputGroup.Addon>}
        </InputGroup>
      )
    }

    if (formType === 'inline' || formType === 'custom') {
      const {
        colLabelStyle,
        colLabelClass,
        colInputStyle,
        colInputClass,
      } = (custom || {});

      return (
        <ErrorBoundary>
          <FormGroup controlId={id} className={formGroupClassName}>
            <Col sm={6} componentClass={ControlLabel} style={colLabelStyle} className={colLabelClass}>
              {prependLabel}
              {label}
              {appendLabel}
              {required && <Required/>}
            </Col>
            <Col sm={6} style={colInputStyle} className={colInputClass}>
              {input}
              {error && <HelpBlock>{error}</HelpBlock>}
            </Col>
          </FormGroup>
        </ErrorBoundary>
      )
    }

    return (
      <ErrorBoundary>
        <FormGroup controlId={id} className={formGroupClassName}>
          {showLabel && <ControlLabel>{label}{required && <Required/>}</ControlLabel>}
          {input}
          {error && <HelpBlock>{error}</HelpBlock>}
        </FormGroup>
      </ErrorBoundary>
    );
  }
}