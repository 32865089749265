import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import PubSub from 'pubsub-js';
import axios from 'axios';
import queryString from 'query-string';

export default ({ bookingId, billId, user }) => {
  const prevUserRef = useRef(null);

  useEffect(() => {
    if (prevUserRef.current !== null && prevUserRef.current !== user && user.isLoggedIn) {
      process();
    }

    prevUserRef.current = user;
  }, [user]);

  const process = () => {
    if (!user.isLoggedIn()) {
      PubSub.publish('Devise.SignIn', { autoProcess: true, modalTitle: 'Sign In' });
      return true;
    }

    const query = queryString.parse(location.search);
    const data = { booking_user_id: query.user_id };

    axios
      .post(`/bookings/${bookingId}/bills/${billId}/prepare`, data)
      .then((response) => {
        window.location = `/bookings/${response.data.data.booking_id}/checkout`;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Button bsStyle="primary" onClick={process}>
      PROCEED TO CHECKOUT
    </Button>
  );
}