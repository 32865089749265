import React, { Component, Fragment } from 'react';
import { Checkbox } from 'react-bootstrap';

export default class Select extends Component {
  render() {
    const {
      placeholder,
      onlyAdmin,
      defaultValue,
      uncheckedValue,
      ...checkboxProps
    } = this.props;

    const value = checkboxProps.value || true;
    const defaultChecked = (defaultValue === value);

    return (
      <Fragment>
        <input
          type="hidden"
          disabled={checkboxProps.disabled}
          name={checkboxProps.name}
          value={uncheckedValue || false}
        />

        <Checkbox {...checkboxProps} value={value} defaultChecked={defaultChecked}>
          <span style={{ color: '#333' }}>
            {onlyAdmin && <span className="sha-admin-highlight">{placeholder}</span>}
            {!onlyAdmin && placeholder}
          </span>
        </Checkbox>
      </Fragment>
    );
  }
}
