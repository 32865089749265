import React, { Component } from 'react';
import Item from "./item";

export default class Table extends Component {
  render() {
    const {
      data,
      status,
      onEdit,
      onDelete,
      onDisable,
      onEnable
    } = this.props;

    if (!(Array.isArray(data) && data.length > 0)) return null;

    let visibleData = data;

    if (status === 'active') visibleData = data.filter((item) =>
      item.future_count > 0 || item.booking_option_items_count === 0
    );

    return (
      <table className="table table-striped table-bordered table-xs">
        <thead>
        <tr>
          <th style={{width: 200}}>Title</th>
          <th style={{width: 60}}>Price</th>
          <th style={{width: 40}}>Type</th>
          <th>Schedule</th>
          <th style={{width: 100}}/>
        </tr>
        </thead>
        <tbody>
        {visibleData.map((bookingOption, index) =>
          <Item
            key={index}
            {...bookingOption}
            onEdit={onEdit}
            onDelete={onDelete}
            onDisable={onDisable}
            onEnable={onEnable}
          />
        )}
        </tbody>
      </table>
    );
  }
}